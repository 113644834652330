import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo, useState } from "react";
import { ICourse } from "../Header";
import Tabs from "../UI/Tabs";
import CourseCard from "./CourseCard";

const coursesQyery = graphql`
  {
    categories: allMdx(
      filter: { fields: { source: { eq: "categories" } } }
      sort: { fields: frontmatter___order }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          subtitle
          description
          slug
          level
          category_name
          background_color
          order
          not_show_in_menu
          background_image
        }
      }
    }
  }
`;

interface ICoursesQuery {
  categories: {
    totalCount: number;
    nodes: Array<{
      frontmatter: ICourse;
    }>;
  };
}

const CoursesList: React.FC = () => {
  const { categories } = useStaticQuery<ICoursesQuery>(coursesQyery);
  const courses = categories.nodes.reduce((arr, category) => {
    if (category.frontmatter?.not_show_in_menu) return arr;

    return [...arr, category.frontmatter];
  }, [] as ICourse[]);
  const [selectedTab, setSelectedTab] = useState(0);

  const structuredCourses = useMemo(() => {
    return courses.reduce(
      (obj, el) => {
        if (el.category_name in obj) {
          obj[el.category_name].push(el);
        }

        return obj;
      },
      {
        ClickHouse: [],
        Concepts: [],
        Tools: [],
      } as { [name: string]: ICourse[] }
    );
  }, [courses]);

  const tabs = [
    {
      id: 0,
      label: "All",
      count: courses.length,
    },
    ...Object.entries(structuredCourses).map(([label, courses], idx) => ({
      id: idx + 1,
      label,
      count: courses.length,
    })),
  ];

  const renderedCourses =
    selectedTab === 0 ? courses : structuredCourses[tabs[selectedTab].label];

  return (
    <div className="w-full pt-6">
      <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} />
      <div className="p-6 lg:pb-10 space-y-6">
        {renderedCourses.map((course) => (
          <CourseCard key={course.title} {...course} />
        ))}
      </div>
    </div>
  );
};

export default CoursesList;
