import React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import OurCourses from "../components/TrainingPage/OurCourses";
import CoursesList from "../components/TrainingPage/CoursesList";
import OurTeam from "../components/TrainingPage/OurTeam";
import Stats from "../components/TrainingPage/Stats";
import Feedback from "../components/TrainingPage/Feedback";

interface IHomePage {
  data: any;
}

const HomePage: React.FC<IHomePage> = ({ data }) => {
  return (
    <>
      <SEO
        url={data?.site?.siteMetadata?.siteUrl}
        title="Ensemble | Learn ClickHouse and Cloud Data Engineering"
        desc={data?.site?.siteMetadata?.description}
        ogImage={`${data?.site?.siteMetadata?.siteUrl}/assets/img/home.png`}
      />
      <DefaultLayout>
      <Hero
        title='ClickHouse Academy'
        description='Level Up Your Skills In ClickHouse and Cloud Data Engineering'
        backgroundColor={["#40b7de", "#1976e0"]}
      />
        <CoursesList />
      </DefaultLayout>
    </>
  );
};

export const query = graphql`
  query lessons {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;

export default HomePage;
