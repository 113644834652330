import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { LevelIcon } from "../../../images/icons";
import { ReactComponent as ClockIcon } from "../../../images/icons/clock.svg";
import { ReactComponent as BookIcon } from "../../../images/icons/book.svg";
import { ReactComponent as ArrowRightIcon } from "../../../images/icons/arrow-right.svg";
import { ICourse } from "../../Header";
import Button from "../../UI/Button";

const courseQuery = graphql`
  {
    categories: allMdx(
      filter: { frontmatter: { type: { in: ["lesson"] } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          category
          time_to_learn
        }
      }
    }
  }
`;

const CourseCard: React.FC<ICourse> = ({
  background_color,
  title,
  description,
  slug,
  level,
  subtitle,
  background_image,
  notebook,
}) => {
  const { categories } = useStaticQuery<{
    categories: {
      nodes: [{ frontmatter: { category: string; time_to_learn: number } }];
    };
  }>(courseQuery);

  const lessons = categories.nodes.filter((category) =>
    category.frontmatter.category.includes(slug)
  );
  const totalTime = lessons.reduce(
    (sum, lesson) => (sum += lesson.frontmatter.time_to_learn ?? 0),
    0
  );
  const hours = (totalTime / 60).toFixed(0);
  const minutes = (totalTime % 60).toFixed(0);
  const [colorFrom, colorTo] = background_color;

  return (
    <div
      className="w-full p-6 lg:p-8 max-w-[1140px] mx-auto flex flex-col lg:flex-row lg:gap-8 rounded-2xl transition-transform"
      style={{
        background: `linear-gradient(to right, ${colorFrom}, ${colorTo})`,
      }}
    >
      <div className="w-full">
        <Link to={notebook ? slug : `/learn/categories/${slug}`}>
          <h4 className="text-2xl lg:text-[26px] font-semibold text-white mb-4">
            {title}
          </h4>
        </Link>
        <p className="text-md text-white font-semibold">{subtitle}</p>
        <p className="hidden lg:block text-sm text-white pt-2">{description}</p>
        <div className="xl:flex items-center justify-between xl:mt-8">
          <div className="my-6 xl:my-0 xl:flex-wrap xl:flex xl:gap-2">
            <div className="flex flex-wrap gap-1.5">
              <div className="flex items-center px-2 py-2 gap-1 border border-white border-opacity-20 h-10 rounded-lg">
                <LevelIcon className="text-white" number={level} />
                <span className="text-white text-sm font-semibold">
                  {level === 1 && "Beginner"}
                  {level === 2 && "Intermediate"}
                  {level === 3 && "Advanced"}
                </span>
              </div>
              {notebook ? "" :
              <div className="flex items-center px-2 py-2 gap-1 border border-white border-opacity-20 h-10 rounded-lg">
                <BookIcon className="text-white" />
                <span className="text-white text-sm font-semibold">
                  {lessons.length} lesson
                  {lessons.length !== 1 ? "s" : ""}
                </span>
              </div>
              }

              {notebook ? "" :
              <div className="flex items-center px-2 py-2 gap-1 border border-white border-opacity-20 h-10 rounded-lg">
                <ClockIcon className="text-white" />
                <span className="text-white text-sm font-semibold">
                  {hours}h {minutes}m
                </span>
              </div>
              }
              {notebook ? "" :
                <div className="flex items-center px-2 py-2 gap-1 border border-white border-opacity-20 h-10 rounded-lg">
                  <StaticImage
                    src="../../../images/me.jpg"
                    className="w-6 h-6 rounded-full"
                    alt="Benjamin Wootton"
                  />
                  <span className="text-white text-sm font-semibold">
                    Benjamin Wootton
                  </span>
                  </div>
              }
              </div>
              
            
          </div>
    
          <Button
            className="w-full"
            size="small"
            to={notebook ? slug : `/learn/categories/${slug}`}
            label={notebook ? "View Item" : "View Course"}
            EndIcon={ArrowRightIcon}
            type="outline"
            color="white"
          />
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
